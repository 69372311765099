const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

let headerBgImageId = 1;
const headerBgStyle = document.getElementsByClassName("head")[0].style;
headerBgStyle.backgroundImage = `url(${imagePath('./header_bg_' + headerBgImageId)})`;
window.setInterval(() => {
    headerBgImageId += 1;
    if (headerBgImageId > 5) {
        headerBgImageId = 1;
    }
    headerBgStyle.backgroundImage = `url(${imagePath('./header_bg_' + headerBgImageId)})`;
}, 5000);
